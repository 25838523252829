import React, { Component } from "react";
import { Link } from "react-router-dom";

// Import Images
import bcode from "../../images/about/bcode.png";
import founder from "../../images/about/bcode4.png";
import devStud from "../../images/about/bcode3.png";
import sheCode from "../../images/about/bcode2.png";
import ptImg1 from "../../images/shap/wave-orange.png";
import ptImg2 from "../../images/shap/circle-small-blue.png";
import ptImg4 from "../../images/shap/square-dots-orange.png";
import ptImg5 from "../../images/shap/square-blue.png";

class aboutSection extends Component {
  render() {
    return (
      <>
        <section className="section-sp1 about-area">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-lg-6 mb-30">
                <div className="about-thumb-area">
                  <ul>
                    <li>
                      <img className="about-thumb1" src={bcode} alt="" />
                    </li>
                    <li>
                      <img className="about-thumb2" src={founder} alt="" />
                    </li>
                    <li>
                      <img
                        className="about-thumb3"
                        src={devStud}
                        alt=""
                        style={{
                          objectFit: "cover",
                        }}
                      />
                    </li>
                    <li>
                      <img
                        className="about-thumb3"
                        src={sheCode}
                        alt=""
                        style={{
                          objectFit: "cover",
                        }}
                      />
                      {/* <div className="exp-bx">
                        {"</bs>"}
                        <span></span>
                      </div> */}
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-lg-6 mb-30">
                <div className="heading-bx">
                  <h6 className="title-ext text-secondary">
                    Game da Bayascript
                  </h6>
                  <h2 className="title">
                    Koyon ƙirkire-ƙirkiren manhajojin zamani da yaren kwamfuta
                    na farko a ƙasar Hausa
                  </h2>
                  <p>
                    BayaScript yaren komfuta ne da kamfanin Syrol Technologies
                    Ltd dake zaune a jihar Kano ya ƙirƙira domin sauƙaƙawa
                    Hausawa koyen haɗa manhajojin waya da na yanar gizo-gizo
                    cikin yaren Hausa. Bayascript na aiki ne da JavaScript a
                    karkashi ne, Bayascript ya daɗa faɗaɗa yaren JavaScript,
                    yana ɗauke da duka abubuwan Javascript da kuma ƙari mai
                    yawa. Da Bayascript yanzu mutum zai iya ƙirƙkirar manhaja
                    cikin sauki da yaren Hausa
                  </p>
                </div>
                <div className="row">
                  <div className="col-lg-6 col-sm-6 mb-30 mb-sm-20">
                    <div className="feature-container feature-bx1 feature1">
                      <div className="icon-md">
                        <span className="icon-cell"></span>
                      </div>
                      <div className="icon-content">
                        <h4 className="ttr-title">Fara koyan Bayascript</h4>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6 col-sm-6 mb-30 mb-sm-20">
                    <div className="feature-container feature-bx1 feature2">
                      <div className="icon-md">
                        <span className="icon-cell"></span>
                      </div>
                      <div className="icon-content">
                        <h4 className="ttr-title">Amfani da yaren hausa</h4>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6 col-sm-6 mb-30 mb-sm-20">
                    <div className="feature-container feature-bx1 feature3">
                      <div className="icon-md">
                        <span className="icon-cell"></span>
                      </div>
                      <div className="icon-content">
                        <h4 className="ttr-title">Tsare-tsare masu kyau</h4>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6 col-sm-6 mb-30 mb-sm-20">
                    <div className="feature-container feature-bx1 feature4">
                      <div className="icon-md">
                        <span className="icon-cell"></span>
                      </div>
                      <div className="icon-content">
                        <h4 className="ttr-title">
                          Ƙir-ƙirar manhajoji a saukake
                        </h4>
                      </div>
                    </div>
                  </div>
                </div>
                {/* <Link to="/about-us" className="btn btn-primary shadow">Read More</Link> */}
              </div>
            </div>
          </div>
          <img className="pt-img1 animate-wave" src={ptImg1} alt="" />
          <img className="pt-img2 animate2" src={ptImg2} alt="" />
          <img className="pt-img3 animate-rotate" src={ptImg5} alt="" />
          <img className="pt-img4 animate-wave" src={ptImg4} alt="" />
          <img className="pt-img5 animate2" src={ptImg5} alt="" />
        </section>
      </>
    );
  }
}

export default aboutSection;
