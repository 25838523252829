import React, { Component } from "react";
import { Link } from "react-router-dom";
import { TypeAnimation } from "react-type-animation";
// Import Images
import bg1 from "../../images/main-banner/bg1.jpg";
import bsl from "../../images/bs-l.png";
import ptImg1 from "../../images/shap/trangle-orange.png";
import ptImg2 from "../../images/shap/square-blue.png";
import ptImg3 from "../../images/shap/chicle-blue-2.png";
import ptImg4 from "../../images/shap/plus-orange.png";
import ptImg5 from "../../images/shap/wave-orange.png";

class aboutSection extends Component {
  render() {
    return (
      <>
        <div
          className="main-banner"
          style={{ backgroundImage: "url(" + bg1 + ")" }}
        >
          <div className="container inner-content">
            <div className="row align-items-center">
              <TypeAnimation
                sequence={[
                  "* Amfani da yaren hausa",
                  1000,
                  "* Ƙir-ƙirar manhajoji a saukake",
                  1000,
                  "* Rubuta kadan samu aiki dayawa",
                  1000,
                  "* Yana aiki a kowacce server ko kwamfuta",
                  1000,
                ]}
                wrapper="span"
                speed={30}
                className="animated-text"
                repeat={Infinity}
              />
              <div className="col-lg-7 col-md-6 col-sm-7">
                <h6 className="title-ext text-primary">
                  Yaren kwamfuta na farko a ƙasar Hausa.
                </h6>
                <h3>
                  BayaScript yaren kwamfuta ne na Hausa da akayi shi dan
                  sauƙaƙawa Hausawa koyan ƙirkire-ƙirkire cikin sauki
                </h3>
                <Link
                  target="_blank"
                  to={"https://www.npmjs.com/package/bayascript"}
                  className="btn btn-secondary btn-lg shadow mt-5"
                >
                  Gwada Amfani da BayaScript
                </Link>
              </div>
              <div className="col-lg-5 col-md-6 col-sm-5">
                <div className="banner-img">
                  <p>
                    <TypeAnimation
                      sequence={["idan(age>=18 kuma age<50){", 1000]}
                      wrapper="span"
                      speed={50}
                      repeat={Infinity}
                      className="animated-code"
                    />
                  </p>
                  <p>
                    <TypeAnimation
                      sequence={[" nuna('Zaka iya zabe')", 1000]}
                      wrapper="span"
                      speed={50}
                      repeat={Infinity}
                      className="animated-code"
                    />
                  </p>
                  <p>
                    <TypeAnimation
                      sequence={["}", 1000]}
                      wrapper="span"
                      speed={50}
                      repeat={Infinity}
                      className="animated-code"
                    />
                    <img src={bsl} alt="" />

                    
                  </p>
                </div>
              </div>
            </div>
          </div>
          <img className="pt-img1 animate1" src={ptImg1} alt="" />
          <img className="pt-img2 animate2" src={ptImg2} alt="" />
          <img className="pt-img3 animate3" src={ptImg3} alt="" />
          <img className="pt-img4 animate4" src={ptImg4} alt="" />
          <img className="pt-img5 animate-wave" src={ptImg5} alt="" />
        </div>
      </>
    );
  }
}

export default aboutSection;
