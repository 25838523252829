import React, { Component } from "react";
import { Link } from "react-router-dom";
import MainBannerSection from "../elements/main-banner";

// Import Images
import bnrImg1 from "../../images/banner/img1.jpg";
import waveBlue from "../../images/shap/wave-blue.png";
import circleDots from "../../images/shap/circle-dots.png";
import plusBlue from "../../images/shap/plus-blue.png";

// Blog Content

class Doc extends Component {
  render() {
    return (
      <>
        <div className="page-content bg-white">
          <div className="banner-wraper">
            <div
              className="page-banner"
              style={{ backgroundImage: "url(" + bnrImg1 + ")" }}
            >
              <div className="container">
                <div className="page-banner-entry text-center">
                  <h1>BayaScript Doc</h1>
                  <nav aria-label="breadcrumb" className="breadcrumb-row">
                    <ul className="breadcrumb">
                      <li className="breadcrumb-item">
                        <Link to="/">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="22"
                            height="22"
                            viewBox="0 0 24 24"
                            fill="none"
                            stroke="currentColor"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            className="feather feather-home"
                          >
                            <path d="M3 9l9-7 9 7v11a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2z"></path>
                            <polyline points="9 22 9 12 15 12 15 22"></polyline>
                          </svg>{" "}
                          Home
                        </Link>
                      </li>
                      <li
                        className="breadcrumb-item active"
                        aria-current="page"
                      >
                        Doc
                      </li>
                    </ul>
                  </nav>
                </div>
              </div>
              <img className="pt-img1 animate-wave" src={waveBlue} alt="" />
              <img className="pt-img2 animate2" src={circleDots} alt="" />
              <img className="pt-img3 animate-rotate" src={plusBlue} alt="" />
            </div>
          </div>
          <section>
            <div className="container">
              <h1>What is Bayascript</h1>
              Bayascript yare ne na kirkire kirkiran manhajan yanar gizo wato
              web programming,da wasu aiyuka da dama domin saukakawa
              programmers. <font color="green">Rabi’u Ali (Rabal)</font> ne ya
              kirkireshi a shekarar 2023
              <p>
                BayaScript babban mataki ne, na harsashen shirye-shirye iri-iri
                da aka fi sani da amfani da shi wajen ci gaban yanar gizo. Ana
                yawan amfani da shi don haɓaka hulɗar gidajen yanar gizo da
                ƙirƙirar abun ciki mai ƙarfi. bayaScript wani muhimmin sashi ne
                na masu binciken gidan yanar gizo, yana bawa masu haɓaka damar
                sarrafa abubuwan da ke kan shafin yanar gizon, amsa ayyukan mai
                amfani, da sabunta abubuwan da aka nuna a hankali.
              </p>
              <p>
                <h1>Meye amfanin Bayascript</h1> Ana amfani da BayaScript sosai
                wajen haɓaka gidan yanar gizon wato (web development) don
                dalilai da yawa:
                <br />
                <br />
                <ol style={{ listStyleType: "decimal" }}>
                  <li>
                    <h4>
                      Haɗin gwiwar Abokin Ciniki (Client-Side Interactivity):
                    </h4>
                    BayaScript shine yaren rubutun gefen abokin ciniki, ma'ana
                    yana gudana a cikin burauzar gidan yanar gizon mai amfani.
                    Wannan yana ba masu haɓakawa damar ƙirƙirar mu'amala mai
                    ma'amala da kuzarin mai amfani waɗanda ke amsa ayyukan mai
                    amfani ba tare da buƙatar tafiya zagaye zuwa uwar garken ba.
                    Masu amfani za su iya fuskantar canje-canje a ainihin
                    lokacin ba tare da sake loda dukkan shafin ba.
                    <br />
                    <br />
                  </li>
                  <li>
                    <h4>Ƙarfafawa (Versatility):</h4> bayaScript harshe ne mai
                    ma'ana wanda za'a iya amfani dashi don ci gaban gaba da
                    ƙarshen baya. A ƙarshen gaba, ana amfani da shi don haɓaka
                    ƙirar mai amfani da ƙirƙirar abun ciki mai ƙarfi, yayin da a
                    ƙarshen baya.
                    <br />
                    <br />
                  </li>
                  <li>
                    <h4>Faɗin karɓowa (Wide Adoption):</h4> BayaScript yana
                    samun goyon bayan duk manyan masu binciken gidan yanar gizo,
                    suna mai da shi yare mai girma don ci gaban yanar gizo.
                    <br />
                    <br />
                  </li>
                  <li>
                    <h4>
                      Shirye-shiryen Asynchronous (Asynchronous Programming):
                    </h4>
                    BayaScript yana goyan bayan shirye-shiryen asynchronous,
                    yana bawa masu haɓaka damar gudanar da ayyukan da ka iya
                    ɗaukar lokaci don kammalawa, kamar karɓar bayanai daga uwar
                    garken, ba tare da toshe aiwatar da wasu lambobi ba. Wannan
                    yana da mahimmanci don ƙirƙirar aikace-aikacen yanar gizo
                    masu amsawa da inganci.
                    <br />
                    <br />
                  </li>
                  <li>
                    <h4>
                      Ci gaban dandamali na Cross-Platform (Cross-Platform
                      Development):
                    </h4>
                    Tare da haɓakar tsarin kamar React Native da tsarin kamar
                    Electron, JavaScript ana ƙara amfani da shi don haɓaka
                    dandamali, ƙyale masu haɓakawa don gina aikace-aikacen da ke
                    gudana akan tsarin aiki da na'urori da yawa.
                    <br />
                    <br />
                  </li>
                </ol>
              </p>
            </div>
          </section>
        </div>
      </>
    );
  }
}

export default Doc;
