import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Accordion } from "react-bootstrap";

// Elements
import LatestNewsSection from "../elements/latest-news-slider";

// Import Images
import bnrImg1 from "../../images/banner/img1.jpg";
import waveBlue from "../../images/shap/wave-blue.png";
import circleDots from "../../images/shap/circle-dots.png";
import plusBlue from "../../images/shap/plus-blue.png";

class Faq extends Component {
  render() {
    return (
      <>
        <div className="page-content bg-white">
          <div className="banner-wraper">
            <div
              className="page-banner"
              style={{ backgroundImage: "url(" + bnrImg1 + ")" }}
            >
              <div className="container">
                <div className="page-banner-entry text-center">
                  <h1>Tambayoyi</h1>
                  <nav aria-label="breadcrumb" className="breadcrumb-row">
                    <ul className="breadcrumb">
                      <li className="breadcrumb-item">
                        <Link to="/">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="22"
                            height="22"
                            viewBox="0 0 24 24"
                            fill="none"
                            stroke="currentColor"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            className="feather feather-home"
                          >
                            <path d="M3 9l9-7 9 7v11a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2z"></path>
                            <polyline points="9 22 9 12 15 12 15 22"></polyline>
                          </svg>{" "}
                          Gida
                        </Link>
                      </li>
                      <li
                        className="breadcrumb-item active"
                        aria-current="page"
                      >
                        Tambayoyi da suka gabata
                      </li>
                    </ul>
                  </nav>
                </div>
              </div>
              <img className="pt-img1 animate-wave" src={waveBlue} alt="" />
              <img className="pt-img2 animate2" src={circleDots} alt="" />
              <img className="pt-img3 animate-rotate" src={plusBlue} alt="" />
            </div>
          </div>

          <section className="section-sp3">
            <div className="container">
              <div className="row">
                <div className="col-lg-6">
                  <Accordion
                    defaultActiveKey="0"
                    className="accordion ttr-accordion1"
                  >
                    <Accordion.Item eventKey="0">
                      <Accordion.Header>
                        Taya zan dorda BayaScript?
                      </Accordion.Header>
                      <Accordion.Body>
                        <p className="mb-0"></p>
                      </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="1">
                      <Accordion.Header>
                        Nayi kokarin dora bayaScript, amma yana nunamin npm not
                        found?
                      </Accordion.Header>
                      <Accordion.Body>
                        <p className="mb-0"></p>
                      </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="2">
                      <Accordion.Header>Ta yaya zan dora npm?</Accordion.Header>
                      <Accordion.Body>
                        <p className="mb-0"></p>
                      </Accordion.Body>
                    </Accordion.Item>

                    {/* <Accordion.Item eventKey="4">
                      <Accordion.Header>
                        We Teach You How To Feel Better?
                      </Accordion.Header>
                      <Accordion.Body>
                        <p className="mb-0"></p>
                      </Accordion.Body>
                    </Accordion.Item> */}
                  </Accordion>
                </div>
                <div className="col-lg-6">
                  <Accordion
                    defaultActiveKey="2"
                    className="accordion ttr-accordion1"
                  >
                    <Accordion.Item eventKey="3">
                      <Accordion.Header>
                        Wani irin na'ura yake dauka?
                      </Accordion.Header>
                      <Accordion.Body>
                        <p className="mb-0"></p>
                      </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="5">
                      <Accordion.Header>
                        Ta yaya zan tuntube ku?
                      </Accordion.Header>
                      <Accordion.Body>
                        <p className="mb-0"></p>
                      </Accordion.Body>
                    </Accordion.Item>
                    {/* <Accordion.Item eventKey="0">
                      <Accordion.Header>
                        How do I withdraw from a subject?
                      </Accordion.Header>
                      <Accordion.Body>
                        <p className="mb-0"></p>
                      </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="1">
                      <Accordion.Header>
                        What’s a payment statement?
                      </Accordion.Header>
                      <Accordion.Body>
                        <p className="mb-0"></p>
                      </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="2">
                      <Accordion.Header>
                        How Can I Contact You?
                      </Accordion.Header>
                      <Accordion.Body>
                        <p className="mb-0"></p>
                      </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="3">
                      <Accordion.Header>
                        How do I withdraw from a subject?
                      </Accordion.Header>
                      <Accordion.Body>
                        <p className="mb-0"></p>
                      </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="4">
                      <Accordion.Header>
                        Understand Doctor Before You Regret?
                      </Accordion.Header>
                      <Accordion.Body>
                        <p className="mb-0"></p>
                      </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="5">
                      <Accordion.Header>
                        How Doctor Can Ease Your Pain?
                      </Accordion.Header>
                      <Accordion.Body>
                        <p className="mb-0"></p>
                      </Accordion.Body>
                    </Accordion.Item> */}
                  </Accordion>
                </div>
              </div>
            </div>
          </section>
        </div>
      </>
    );
  }
}

export default Faq;
